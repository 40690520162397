<template>
  <div class="change-email">
    <van-nav-bar
      :title="$t('auth.changeEmail.title')"
      fixed
      placeholder
    >
      <template #left>
        <van-icon
          :name="$icon('back')"
          size="16"
          color="#000"
          is-link
          @click="$router.back()"
        />
      </template>
    </van-nav-bar>
    <div class="container">
      <van-form
        validate-first
        @submit="submitForm"
      >
        <field-list>
          <van-field
            v-model="email"
            reference="email"
            name="email"
            type="email"
            label="Email"
            tabindex="1"
            :rules="[
              { required: true, message: $t('auth.validation.email.required') },
              { validator: emailValidator, message: $t('auth.validation.email.invalid') },
            ]"
            :error="!!emailError || email.validateFailed"
            :error-message="emailError"
          />
        </field-list>
        <infobox
          :icon="$icon('info-purple')"
          class="change-email__warning"
        >
          {{ $t('auth.changeEmail.warning') }}
        </infobox>
        <van-button
          type="primary"
          native-type="submit"
          block
          round
        >
          {{ $t('auth.changeEmail.submit') }}
        </van-button>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Notify } from 'vant';
import { auth } from '@/firebase';
import { emailValidator } from '@/validators';
import FieldList from '@/components/FieldList';
import Infobox from '@/components/Infobox';

export default {
  components: {
    FieldList,
    Infobox,
  },
  data() {
    return {
      email: '',
      emailError: '',
    };
  },
  methods: {
    emailValidator(value) {
      return emailValidator(value);
    },
    submitForm(values) {
      auth.currentUser.updateEmail(values.email)
        .then(() => {
          Notify({
            type: 'primary',
            message: this.$i18n.t('auth.changeEmail.success'),
            duration: 5000,
          });
        })
        .catch((error) => {
          this.emailError = this.$i18n.t(`firebase.${error.code}`);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '~@/styles/config' as config;

.change-email__warning {
  margin: 2rem 0;
}
</style>
